/* eslint-disable no-nested-ternary */
/* eslint-disable no-inner-declarations */
/* eslint-disable compat/compat */
/* eslint-disable no-new */
/* eslint-disable no-use-before-define */
/* eslint-disable react/button-has-type */
import React, { useState, useRef, useEffect } from 'react';
// import { getResponsiveImage } from '@/componentWebs/ImageNew';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { isValidURL, transformUrl, FileMimeType, formatSeconds } from '@/utils/utils';
import { getResponsiveImage } from '@/componentWebs/ImageNew';
// import router from 'next/router';
import _ from 'lodash';

function SearchDownload(props) {
  const { dispatch, url, intl, dataCategory, dataCategoryInfo } = props;
  const [visibleHuongdan, setVisibleHuongdan] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showQualityLabel, setShowQualityLabel] = useState(false);
  const [link, setLink] = useState(url || '');
  const [listVideo, setListVideo] = useState({});
  const listQualityLabel = useRef(null);
  const [resultError, setResultError] = useState(false);
  const [fileActive, setFileActive] = useState({});
  const [progress, setProgress] = useState([]);
  const [linkdie, setLinkdie] = useState([]);

  useEffect(
    () => {
      const handleClickOutside = event => {
        if (listQualityLabel.current && !listQualityLabel.current.contains(event.target)) {
          setShowQualityLabel(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    },
    [listQualityLabel]
  );

  const handleInputChange = event => {
    setLink(event.target.value);
    setError('');
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (link) {
      if (isValidURL(link)) {
        setResultError(false);
        setError('');
        setIsLoading(true);
        if (link.includes('facebook.com') || link.includes('fb.com') || link.includes('fb.watch')) {
          let urlNew = link;
          urlNew = urlNew.replace('m.', '');
          urlNew = transformUrl(urlNew);
          dispatch({
            type: 'download/fetchByLinkfacebook',
            payload: {
              link: urlNew,
              orginLink: link,
            },
            callback: res => {
              setIsLoading(false);
              if (res?.success) {
                const obj = res?.result;
                if (!obj?.id) {
                  setListVideo({});
                  setFileActive({});
                  setResultError(true);
                  return;
                }
                const convert = {
                  ...obj,
                  video: {
                    ...obj,
                    music: [],
                    videos: [
                      {
                        hasAudio: true,
                        qualityLabel: 'Video HD',
                        url: obj?.hdUrl,
                      },
                      {
                        hasAudio: true,
                        qualityLabel: 'Video SD',
                        url: obj?.sdUrl,
                      },
                    ]?.filter(i => i?.url),
                  },
                };
                setListVideo(convert);
                setFileActive({ ...convert?.video?.videos?.[0], type: 1 });
              } else {
                setListVideo({});
                setFileActive({});
                setResultError(true);
              }
            },
          });
        } else if (link.includes('youtube.com') || link.includes('youtu.be')) {
          dispatch({
            type: 'download/fetchByLinkyoutube',
            payload: { link },
            callback: res => {
              setIsLoading(false);
              if (res?.success) {
                if (!res?.result?.video?.id) {
                  setListVideo({});
                  setFileActive({});
                  setResultError(true);
                  return;
                }
                const musicSource = res?.result?.video?.music || [];
                const videoSource =
                  res?.result?.video?.videos?.filter(video => video?.qualityLabel) || [];
                const result = [];
                const uniqueQualities = new Set();
                // eslint-disable-next-line no-unused-expressions
                videoSource?.forEach((i, index) => {
                  if (
                    !i.hasAudio &&
                    !uniqueQualities.has(i.qualityLabel) &&
                    musicSource?.length > 0 &&
                    videoSource?.filter(i2 => i?.qualityLabel === i2?.qualityLabel && !i2.hasAudio)
                      ?.length > 0
                  ) {
                    result.push({
                      id: index,
                      hasAudio: true,
                      isMerge: true,
                      'content-type': i?.['content-type'],
                      qualityLabel: i?.qualityLabel,
                      music: musicSource?.map(i2 => ({
                        url: i2?.url,
                      })),
                      video: videoSource
                        ?.filter(i2 => i?.qualityLabel === i2?.qualityLabel && !i2.hasAudio)
                        ?.map(i2 => ({
                          url: i2?.url,
                        })),
                    });
                    uniqueQualities.add(i.qualityLabel);
                  }
                  result.push(i);
                });
                const uniqueQualityLabels = _(result)
                  .orderBy(['isMerge'], ['asc']) // Sắp xếp ưu tiên `hasAudio = true`
                  .uniqBy('qualityLabel') // Lọc duy nhất theo `qualityLabel`
                  .value();
                const remainingItems = _.orderBy(
                  _.differenceWith(result, uniqueQualityLabels, _.isEqual),
                  ['hasAudio', 'qualityLabel'],
                  ['desc', 'desc']
                );
                const convert = {
                  ...res?.result,
                  video: {
                    ...res?.result?.video,
                    videos: [],
                    remainingItems,
                    uniqueQualityLabels,
                    music: musicSource,
                  },
                };
                setListVideo(convert);
                setFileActive({ ...convert?.video?.uniqueQualityLabels?.[0], type: 1 });
              } else {
                setListVideo({});
                setFileActive({});
                setResultError(true);
              }
            },
          });
        } else if (link.includes('tiktok.com')) {
          dispatch({
            type: 'download/fetchByLinktiktok',
            payload: { link },
            callback: res => {
              setIsLoading(false);
              if (res?.success) {
                const obj = res?.result?.[0];
                if (!obj?.video?.id) {
                  setListVideo({});
                  setFileActive({});
                  setResultError(true);
                  return;
                }
                const convert = {
                  ...obj,
                  video: {
                    ...obj?.video,
                    music: [
                      {
                        url: obj?.video?.music,
                      },
                    ]?.filter(i => i?.url),
                    videos: [
                      {
                        hasAudio: true,
                        qualityLabel: 'no Logo',
                        url: obj?.video?.url,
                      },
                      {
                        hasAudio: true,
                        qualityLabel: 'has Logo',
                        url: obj?.video?.urlWatermark,
                      },
                    ]?.filter(i => i?.url),
                  },
                };
                setListVideo(convert);
                setFileActive({ ...convert?.video?.videos?.[0], type: 1 });
              } else {
                setListVideo({});
                setFileActive({});
                setResultError(true);
              }
            },
          });
        } else if (link.includes('douyin.com')) {
          dispatch({
            type: 'download/fetchByLinkdouyin',
            payload: { link },
            callback: res => {
              setIsLoading(false);
              if (res?.success) {
                const obj = res?.result?.[0];
                if (!obj?.video?.id) {
                  setListVideo({});
                  setFileActive({});
                  setResultError(true);
                  return;
                }
                const convert = {
                  ...obj,
                  video: {
                    ...obj?.video,
                    music: [
                      {
                        url: obj?.video?.music,
                      },
                    ]?.filter(i => i?.url),
                    videos: [
                      {
                        hasAudio: true,
                        qualityLabel: 'no Logo',
                        url: obj?.video?.url,
                      },
                      {
                        hasAudio: true,
                        qualityLabel: 'has Logo',
                        url: obj?.video?.urlWatermark,
                      },
                    ]?.filter(i => i?.url),
                  },
                };
                setListVideo(convert);
                setFileActive({ ...convert?.video?.videos?.[0], type: 1 });
              } else {
                setListVideo({});
                setFileActive({});
                setResultError(true);
              }
            },
          });
        } else if (link.includes('pinterest.com') || link.includes('pin.it')) {
          dispatch({
            type: 'download/fetchByLinkprintest',
            payload: { link },
            callback: res => {
              setIsLoading(false);
              if (res?.success) {
                const obj = res?.result;
                if (!obj?.id) {
                  setListVideo({});
                  setFileActive({});
                  setResultError(true);
                  return;
                }
                const convert = {
                  ...obj,
                  video: {
                    ...obj?.video,
                    music: [],
                    videos: [
                      {
                        hasAudio: true,
                        qualityLabel: 'Video',
                        url: obj?.video?.url,
                      },
                    ]?.filter(i => i?.url),
                  },
                };
                setListVideo(convert);
                setFileActive({ ...convert?.video?.videos?.[0], type: 1 });
              } else {
                setListVideo({});
                setFileActive({});
                setResultError(true);
              }
            },
          });
        } else if (link.includes('instagram.com')) {
          dispatch({
            type: 'download/fetchByLinkinstagram',
            payload: { link },
            callback: res => {
              setIsLoading(false);
              if (res?.success) {
                const obj = res?.result;
                if (!obj?.id) {
                  setListVideo({});
                  setFileActive({});
                  setResultError(true);
                  return;
                }
                const convert = {
                  id: obj?.id,
                  video: {
                    id: obj?.id,
                    content: obj?.title || obj?.content,
                    cover: obj?.thumbnail,
                    music: [],
                    videos: obj?.videos?.map(i => ({
                      ...i,
                      qualityLabel: i?.quality,
                      hasAudio: true,
                    })),
                  },
                };

                setListVideo(convert);
                setFileActive({ ...convert?.video?.videos?.[0], type: 1 });
              } else {
                setListVideo({});
                setFileActive({});
                setResultError(true);
              }
            },
          });
        } else if (
          link.includes('x.com') ||
          link.includes('twiter.com') ||
          link.includes('twitter.com')
        ) {
          dispatch({
            type: 'download/fetchByLinktwiter',
            payload: { link },
            callback: res => {
              setIsLoading(false);
              if (res?.success) {
                const obj = res?.result;
                if (!obj?.id) {
                  setListVideo({});
                  setFileActive({});
                  setResultError(true);
                  return;
                }
                const convert = {
                  id: obj?.id,
                  video: {
                    id: obj?.id,
                    content: obj?.title || obj?.content,
                    cover: obj?.thumbnail,
                    music: [],
                    videos: obj?.videos?.map(i => ({
                      ...i,
                      qualityLabel: i?.quality,
                      hasAudio: true,
                    })),
                  },
                };

                setListVideo(convert);
                setFileActive({ ...convert?.video?.videos?.[0], type: 1 });
              } else {
                setListVideo({});
                setFileActive({});
                setResultError(true);
              }
            },
          });
        } else {
          setIsLoading(false);
          setListVideo({});
          setFileActive({});
          setResultError(true);
        }
      } else {
        setError(intl.formatMessage({ id: 'errorFormat' }));
      }
    }
  };

  const handleClear = () => {
    setLink('');
    setError('');
  };
  const handleClick = debounce(() => {
    setVisibleHuongdan(pre => !pre);
  }, 200);

  function hexToRgba(hex, alpha) {
    const color = hex?.startsWith('#') ? hex : '#000000';
    // Chuyển mã màu hex sang giá trị RGB
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // Trả về giá trị RGBA
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  const checkDownloadProgress = async item => {
    try {
      const res = await fetch(`/api/progress`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ taskId: item?.randomNumber }),
      });
      const data = await res.json();
      // console.log('datadata', data);

      if (data.progress !== undefined) {
        setProgress(
          pre =>
            pre?.map(i => ({
              ...i,
              progress:
                item?.randomNumber === i?.id
                  ? Number(data.progress) === 0
                    ? Math.min((i?.progress + Math.random() * 3 + 1).toFixed(2), 98.2)
                    : Math.max(Number(data.progress), i?.progress)
                  : i?.progress,
            })) || []
        );
      }
      // console.log(data.progress);
    } catch (erroree) {
      console.error('Error checking download progress:', erroree);
    }
  };

  const downloadVideo = async item => {
    setFileActive(item);

    const min = 1;
    const max = 9999999999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    setProgress(pre => [
      ...pre,
      {
        id: randomNumber,
        name: `downloadvideoVN_${item?.type === 1 ? item?.qualityLabel : 'audio'}`,
        progress: 0,
      },
    ]);
    const data = {
      ...item,
      randomNumber,
    };
    // checkDownloadProgress(data);
    const intervalId = setInterval(() => checkDownloadProgress(data), 3000);
    const response = await fetch(`/api/mergeMedia`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    clearInterval(intervalId);
    setProgress(pre => pre?.filter(i => i?.id !== randomNumber) || []);
    // clearInterval(intervalId);
    if (response.ok) {
      const downloadLink = document.createElement('a');
      downloadLink.href = result?.downloadUrl;
      downloadLink.download = `downloadvideoVN_${item?.type === 1 ? item?.qualityLabel : 'audio'}${
        result?.extension
      }`;
      document.body.appendChild(downloadLink);
      downloadLink.click(); // Kích hoạt tải file
      document.body.removeChild(downloadLink); // Xóa thẻ a sau khi tải xong
    } else {
      // eslint-disable-next-line no-alert
      alert(intl.formatMessage({ id: 'downloadFalse' }));
      // setLinkdie(pre => [...pre, item?.url]);
    }
  };

  const checkStatus = async item => {
    const response = await fetch(`/api/mergeMedia`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    });
    const result = await response.json();
    if (!(response.ok && (result?.status === 200 || result?.status === 202))) {
      setLinkdie(pre => [...pre, item?.url]);
    }
  };

  // const deleteFile = async fileName => {
  //   try {
  //     const response = await fetch(`/api/downloadVideo?fileName=${encodeURIComponent(fileName)}`, {
  //       method: 'DELETE',
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       console.log(data.message); // Ví dụ: 'File successfully deleted'
  //     } else {
  //       console.error('Failed to delete file');
  //     }
  //   } catch (errore) {
  //     console.error('Error:', errore);
  //   }
  // };

  return (
    <div className="downloader-block">
      <div className="open-link-form-all-block open-link-form-old">
        <div className="main-form-box">
          <div className="main-form-box-left">
            <div id="link-form-old-cont">
              <div className="downloader-2 downloader-2-part1">
                <div
                  className="search-panel__container container_arrow_new"
                  style={{
                    background:
                      dataCategoryInfo?.description &&
                      hexToRgba(dataCategoryInfo?.description, 0.05),
                  }}
                >
                  <h1 className="search-panel__mobile__header">
                    {dataCategoryInfo?.image?.file && (
                      <img
                        className="lazyload"
                        src={getResponsiveImage(dataCategoryInfo?.image?.file, [50, 50, 50])}
                        alt=""
                      />
                    )}
                    {intl.formatMessage({ id: 'titleSearch' })}
                  </h1>
                </div>
                {/* main form */}
                <form
                  onSubmit={handleSubmit}
                  className="sf-form link-form"
                  target="sf_frame"
                  id="sf_form"
                >
                  <div className="form-block">
                    <div className="l-box">
                      <div className="tarea-wrap">
                        <input
                          type="text"
                          value={link}
                          onChange={handleInputChange}
                          placeholder={intl.formatMessage({ id: 'placeholderSearch' })}
                        />
                        <a
                          onClick={handleClear}
                          className="clear-btn"
                          style={{ display: (link && 'inline') || 'none' }}
                        />
                      </div>
                    </div>
                    <div className="r-box">
                      <button
                        type="submit"
                        name="sf_submit"
                        id="sf_submit"
                        className="submit"
                        style={{ background: dataCategoryInfo?.description }}
                      >
                        {intl.formatMessage({ id: 'download' })}
                      </button>
                    </div>
                  </div>
                  {error && <p style={{ color: 'red', fontSize: 14 }}>{error}</p>}
                </form>
              </div>
              <div className="main-form__wrapper">
                <div
                  className="main-form__watch main-form__watch_show"
                  style={{ display: visibleHuongdan && 'none' }}
                  onClick={event => {
                    event.stopPropagation();
                    handleClick();
                  }}
                >
                  <img className="lazyload" src="/downloadvideo/images/btnPlay.svg" alt="" />
                  <button className="main-form__button" type="button">
                    {intl.formatMessage({ id: 'howtodownload' })}
                  </button>
                  <span>{intl.formatMessage({ id: 'seeInstructions' })}</span>
                </div>
                {visibleHuongdan && (
                  <div className="main-form__video" style={{ display: 'block' }}>
                    <video
                      width="auto"
                      height="auto"
                      playsInline
                      loop
                      muted
                      controls
                      className="search-result-video"
                    >
                      <source
                        src={getResponsiveImage(
                          dataCategory?.slice(0, 1)?.[0]?.image?.file,
                          'video'
                        )}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="downloader-2 downloader-2-part2">
        {isLoading && (
          <div id="sf_indicator_box" style={{}}>
            <div id="sf_indicator">
              <p>{intl.formatMessage({ id: 'loadingSearch' })}</p>
              <img className="lazyload" src="/downloadvideo/images/busy.gif" alt="..." />
            </div>
          </div>
        )}
        {!resultError && listVideo?.video?.id && (
          <div id="sf_result">
            <div className="media-result">
              <div className="result-box video popup-in-output" data-hid={101} style={{}}>
                <div className="thumb-box thumb-272 ">
                  <a href="#" className="clip" data-player-id="video_player_1724137476433_8969">
                    <img
                      src={listVideo?.video?.cover}
                      className="thumb lazyload"
                      alt={listVideo?.video?.content || ''}
                    />
                    <span className="play-btn" />
                  </a>
                </div>
                <div className="info-box">
                  <div className="meta">
                    <div className="row title" title={listVideo?.video?.content}>
                      {listVideo?.video?.content}
                    </div>
                    {listVideo?.video?.lengthSeconds && (
                      <div className="row duration" title="">
                        {formatSeconds(listVideo?.video?.lengthSeconds)}
                      </div>
                    )}
                  </div>
                  <div
                    className={`link-box ${(showQualityLabel && 'expand') || ''}`}
                    ref={listQualityLabel}
                  >
                    <div className="def-btn-box">
                      <a
                        onClick={() => {
                          if (!fileActive?.url) {
                            downloadVideo(fileActive);
                          } else {
                            checkStatus(fileActive);
                          }
                        }}
                        href={fileActive?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {intl.formatMessage({ id: 'download' })}
                      </a>
                    </div>
                    <div
                      className="drop-down-box"
                      onClick={() => {
                        // if (!showQualityLabel) {
                        setShowQualityLabel(!showQualityLabel);
                        // }
                      }}
                    >
                      <div className="def-btn-name">
                        {FileMimeType[(fileActive?.['content-type'])] || 'mp4'}{' '}
                        <span className="subname">
                          {fileActive?.type === 1
                            ? fileActive?.qualityLabel
                            : intl.formatMessage({ id: 'audio' })}
                        </span>
                      </div>
                      <div className="list">
                        <div className="links">
                          <div className="main">
                            {listVideo?.video?.cover && (
                              <div className="link-group" key="Thumbnail">
                                <a
                                  title="img"
                                  className="link link-download subname ga_track_events"
                                  href={listVideo?.video?.cover}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {intl.formatMessage({ id: 'thumbnail' })}
                                </a>
                              </div>
                            )}
                            {listVideo?.video?.uniqueQualityLabels
                              ?.filter(item => !linkdie.includes(item.url))
                              ?.map(i => (
                                <div className="link-group" key={i?.url}>
                                  <a
                                    title={`video format: ${i?.qualityLabel}`}
                                    className={`${(!i?.hasAudio && 'no-audio') ||
                                      ''} link link-download subname ga_track_events`}
                                    // href={i?.url}
                                    // target="_blank"
                                    // rel="noopener noreferrer"
                                    onClick={() => downloadVideo({ ...i, type: 1 })}
                                  >
                                    {i?.qualityLabel}{' '}
                                    <span className="subname">
                                      {FileMimeType[i['content-type']] || 'mp4'}
                                    </span>
                                  </a>
                                </div>
                              ))}
                            {listVideo?.video?.videos
                              ?.filter(
                                item => (item?.url && !linkdie.includes(item.url)) || !item?.url
                              )
                              ?.map(i => (
                                <div className="link-group" key={i?.url || i?.id}>
                                  <a
                                    title={`video format: ${i?.qualityLabel}`}
                                    className={`${(!i?.hasAudio && 'no-audio') ||
                                      ''} link link-download subname ga_track_events`}
                                    href={i?.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => checkStatus(i)}
                                  >
                                    {i?.qualityLabel}{' '}
                                    <span className="subname">
                                      {FileMimeType[i['content-type']] || 'mp4'}
                                    </span>
                                  </a>
                                </div>
                              ))}
                            {listVideo?.video?.music
                              ?.filter(item => !linkdie.includes(item.url))
                              ?.map(i => (
                                <div className="link-group" key={i?.url}>
                                  <a
                                    title={`audio format: ${FileMimeType[i['content-type']] ||
                                      'mp3'}`}
                                    className="link link-download subname ga_track_events"
                                    href={i?.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => checkStatus(i)}
                                  >
                                    {intl.formatMessage({ id: 'audio' })}
                                    <span className="subname">
                                      {FileMimeType[i['content-type']] || 'mp3'}
                                    </span>
                                  </a>
                                </div>
                              ))}
                            {listVideo?.video?.remainingItems
                              ?.filter(
                                item => (item?.url && !linkdie.includes(item.url)) || !item?.url
                              )
                              ?.map(i => (
                                <div className="link-group" key={i?.url || i?.id}>
                                  <a
                                    title={`video format: ${i?.qualityLabel}`}
                                    className={`${(!i?.hasAudio && 'no-audio') ||
                                      ''} link link-download subname ga_track_events`}
                                    href={i?.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => checkStatus(i)}
                                  >
                                    {i?.qualityLabel}{' '}
                                    <span className="subname">
                                      {FileMimeType[i['content-type']] || 'mp4'}
                                    </span>
                                  </a>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="def-file-info" />
                  </div>
                </div>
              </div>
              {progress?.length > 0 && (
                <div className="listprogress">
                  {progress?.map(i => (
                    <div className="labelprogress" key={i?.id}>
                      <span className="progressName">{i?.name}</span>
                      <span className="progressBar">
                        <progress max="100" value={i?.progress}>
                          {i?.progress}%
                        </progress>
                        <span className="progressValue">{i?.progress}%</span>
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        {resultError && (
          <div id="sf_result">
            <div className="result-box simple center result-failure" data-hid="other" style={{}}>
              {intl.formatMessage({ id: 'errorLink' })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default connect()(SearchDownload);
